import {useState, useEffect} from "react"

export default function useOverscrolled() {
	const [isOverscrolled, setIsOverscrolled] = useState(false)
	useEffect(() => {
		let previousScroll = 0
		let translate = 0
		let previousDirection = "down"
		const upBreakpoint = 1
		const downBreakpoint = 56

		const onScroll = () => {
			const currentScroll = document.documentElement.scrollTop
			const difference = currentScroll - previousScroll
			const currentDirection = difference > 0 ? "down" : "up"
			const directionChanged = previousDirection !== currentDirection

			translate += difference

			if (directionChanged) {
				translate = 0
			}
			if (currentScroll <= downBreakpoint) {
				setIsOverscrolled(false)
			} else {
				if (translate >= downBreakpoint) {
					setIsOverscrolled(true)
				} else if (translate <= -upBreakpoint) {
					setIsOverscrolled(false)
				}
			}

			previousDirection = currentDirection
			previousScroll = currentScroll
		}

		window.addEventListener("scroll", onScroll)

		return () => window.removeEventListener("scroll", onScroll)
	}, [])
	return isOverscrolled
}
