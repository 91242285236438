import {useState, useEffect, useCallback} from "react"
import {reachGoal} from "js/ym"

export default function useNightMode() {
	const [nightModeEnabled, setNightModeEnabled] = useState(false)
	const nightModeToggle = useCallback(force => {
		const isForced = typeof force === "boolean"
		if (isForced) {
			setNightModeEnabled(force)
			window.localStorage.setItem("nightModeEnabled", force)
			reachGoal(force ? "nightmode_on" : "nightmode_off")
		} else {
			setNightModeEnabled(prevValue => {
				const newValue = !prevValue
				window.localStorage.setItem("nightModeEnabled", newValue)
				reachGoal(newValue ? "nightmode_on" : "nightmode_off")
				return newValue
			})
		}
	}, [])

	useEffect(() => {
		document.body.setAttribute(
			"data-theme",
			nightModeEnabled ? "dark" : "light"
		)
		let meta = document.querySelector("meta[name='theme-color']")
		if (!meta) {
			meta = document.createElement("meta")
			meta.setAttribute("name", "theme-color")
			document.head.appendChild(meta)
		}
		meta.setAttribute("content", nightModeEnabled ? "#202123" : "#5e0fec")
	}, [nightModeEnabled])

	useEffect(() => {
		const mediaQuery =
			window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)")
		const lsValue = window.localStorage.getItem("nightModeEnabled")
		if (lsValue) {
			setNightModeEnabled(lsValue === "true")
		} else {
			setNightModeEnabled(
				window.matchMedia &&
					window.matchMedia("(prefers-color-scheme: dark)").matches
			)
		}

		const onChangeMode = e => {
			setNightModeEnabled(e.matches)
		}

		mediaQuery?.addEventListener &&
			mediaQuery.addEventListener("change", onChangeMode)

		return () =>
			mediaQuery?.removeEventListener &&
			mediaQuery.removeEventListener("change", onChangeMode)
	}, [])

	return {nightModeEnabled, nightModeToggle}
}
