import React from "react"
import classnames from "classnames"
import useOverscrolled from "hooks/useOverscrolled"

import styles from "./index.module.scss"

const BottomActions = props => {
	const {children, className, ...rest} = props
	const isOverscrolled = useOverscrolled()

	return (
		<div
			className={classnames(styles.root, className)}
			data-overscrolled={isOverscrolled}
			{...rest}
		>
			{children}
		</div>
	)
}

export default BottomActions
