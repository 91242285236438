import React, {useState, useCallback, useEffect, useRef} from "react"
import useApi from "api/useApi"
import {useSnackbar} from "notistack"
import useDialog from "hooks/useDialog"
import {reachGoal} from "js/ym"

import FadedAttention from "components/FadedAttention"
import BottomActions from "components/BottomActions"
import Box from "@mui/material/Box"
import Button from "components/Button"
import PrepareQuestionCard from "components/QuestionCard/Prepare"
import Loading from "components/Loading"
import ErrorMessageBody from "components/ErrorMessageBody"
import Subject from "components/Subject"

import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded"
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded"
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded"
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded"

import styles from "./index.module.scss"

const App = props => {
	const {active, openPrepareWithSubject} = props

	const {
		open: openRemoveDialog,
		close: closeRemoveDialog,
		props: removeDialogProps,
		Component: RemoveDialog,
	} = useDialog()

	const {getBookmarks, getFavoriteSubjects, clearBookmarks} = useApi()
	const {enqueueSnackbar} = useSnackbar()

	const [subjects, setSubjects] = useState([])
	const [questions, setQuestions] = useState([])
	const questionsLength = useRef(questions.length)
	const [isLoading, setIsLoading] = useState(true)
	const [isRemoving, setIsRemoving] = useState(false)
	const [isAnswersShown, setIsAnswersShown] = useState(false)

	const onRemove = useCallback(async () => {
		setIsRemoving(true)
		const {status} = await clearBookmarks()
		if (status) {
			enqueueSnackbar({
				message: "Все ваши закладки были удалены.",
				variant: "success",
			})
			setQuestions([])
			closeRemoveDialog()
		} else {
			enqueueSnackbar({
				message: "Ошибка. Не удалось очистить закладки.",
				variant: "error",
			})
		}
		setIsRemoving(false)
	}, [clearBookmarks, enqueueSnackbar, closeRemoveDialog])

	useEffect(() => {
		questionsLength.current = questions.length
	}, [questions])

	useEffect(() => {
		if (!active) return
		;(async () => {
			questionsLength.current === 0 && setIsLoading(true)
			try {
				const {status, data} = await getBookmarks()
				if (status) {
					data.forEach(
						item =>
							(item.subject = {
								id: item.subject.id,
								course: item.subject.course,
								date: item.subject.date,
								language: item.subject.language,
								name: item.subject.name,
								questions: item.subject.questions,
								isSaved: item.subject.is_saved,
								isNew: item.subject.is_new,
								//usedCount: item.used_count,
							})
					)
					setQuestions(data)
				} else {
					enqueueSnackbar({
						message: "Не удалось загрузить закладки.",
						variant: "error",
					})
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось загрузить закладки. Проверьте подключение к сети."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
			setIsLoading(false)
		})()
	}, [getBookmarks, enqueueSnackbar, active])

	useEffect(() => {
		if (!active) return
		;(async () => {
			try {
				const {status, data} = await getFavoriteSubjects()
				if (status) {
					setSubjects(
						data.map(item => ({
							id: item.id,
							course: item.course,
							date: item.date,
							language: item.language,
							name: item.name,
							questions: item.questions,
							isSaved: true,
							isNew: item.is_new,
							//usedCount: item.used_count,
						}))
					)
				} else {
					enqueueSnackbar({
						message: "Не удалось сохраненные предметы.",
						variant: "error",
					})
				}
			} catch (err) {
				enqueueSnackbar({
					message: (
						<ErrorMessageBody
							message="Не удалось загрузить сохраненные предметы. Проверьте подключение к сети."
							errors={{
								message: err.message,
								res: err.response,
							}}
						/>
					),
					variant: "error",
				})
				reachGoal("error")
			}
		})()
	}, [getFavoriteSubjects, enqueueSnackbar, active])

	return (
		<>
			<RemoveDialog
				{...removeDialogProps}
				title="Очистить все закладки?"
				action={
					<>
						<Button
							variant="negative"
							wide
							onClick={onRemove}
							isLoading={isRemoving}
							iconAfter={DeleteForeverRoundedIcon}
						>
							Да, очистить
						</Button>
						<Button
							variant="primary"
							wide
							onClick={closeRemoveDialog}
						>
							Отменить
						</Button>
					</>
				}
			>
				Все ваши текущие закладки будут удалены. Это относится только к
				вопросам.
			</RemoveDialog>

			{isLoading && <Loading />}

			{!isLoading && questions.length === 0 && subjects.length === 0 && (
				<FadedAttention
					icon={BookmarkRoundedIcon}
					message="Сюда будут попадать сохраненные вами вопросы и предметы"
				/>
			)}

			{!isLoading &&
				subjects.length > 0 &&
				subjects.map((item, index) => (
					<Box key={`s${item.id}`} mb={2}>
						<Subject
							onClick={() => openPrepareWithSubject(item)}
							{...item}
						/>
					</Box>
				))}

			{!isLoading && questions.length > 0 && (
				<>
					{questions.map((item, index) => (
						<Box key={item.id} mb={2}>
							<PrepareQuestionCard
								index={index + 1}
								isAnswersShown={isAnswersShown}
								note={
									<>
										из предмета «
										<span
											className={styles.a}
											onClick={() =>
												openPrepareWithSubject(
													item.subject
												)
											}
										>
											{item.subject.name}
										</span>
										»
									</>
								}
								{...item}
							/>
						</Box>
					))}

					<BottomActions className={styles.bottomActions}>
						<div className={styles.actionsWrapper}>
							<Button
								variant="negative"
								iconAfter={DeleteForeverRoundedIcon}
								onClick={openRemoveDialog}
							>
								Очистить
							</Button>
							<Button
								variant="primary"
								iconAfter={
									isAnswersShown
										? VisibilityOffRoundedIcon
										: RemoveRedEyeRoundedIcon
								}
								onClick={() => setIsAnswersShown(prev => !prev)}
							>
								Ответы
							</Button>
						</div>
					</BottomActions>
				</>
			)}
		</>
	)
}

export default App
